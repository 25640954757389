section {
    display: flex;
    align-items: flex-start;
}

nav.v-nav {
    width: 30px;
    transition: width linear 0.3s;
}
nav.v-nav:hover {
    padding-top: 10px;
    width: var(--nav-width);
}

#main {
    flex: 1;
    margin-left: 5px;
}

.main-head {
    padding: 5px 20px 5px 0;
    margin-bottom: 10px;
    border-bottom: 1px solid var(--gray);
}

.main-body {
    padding-right: 20px;
}

/* TABLE */

table {
    border-collapse: collapse;
    width: 100%;
}

thead {
    background-color: var(--gray);
}

th, td {
    text-align: start;
    padding: 0 8px;
}

th {
    padding: 12px 8px;
}

th {
    font-family: var(--font-text-medium);
}

tr {
    transition: var(--transition);
}

td {
    font-size: 0.9em;
    border-bottom: 1px solid var(--gray);
}

tbody tr:hover {
    background-color: var(--lightGray);
}

tbody tr.disable {
    background-color: var(--errorColorLight);
}

.table-actions svg {
    visibility: hidden;
}

tbody tr:hover .table-actions svg {
    visibility: visible;
}

.table-actions svg {
    transform: scale(0.5);
    cursor: pointer;
    fill: var(--onSurface);
}

.list-loader {
    display: flex;
    justify-content: center;
    padding-top: 150px;
}