.compagny-info{
    z-index: -500;
}
#Complogo{
    display: inline-block;
    position: relative;
    width: 10%;
    height: 100px;
    margin: 50px auto;
    left: 30%;
}
#Complogo img{
    display: block;
    position: relative;
    width: 100%;
    height: auto;
    margin: 0 auto;
}
#compName{
    display: inline-block;
    position: relative;
    margin: 10px auto;
    width: auto;
    height: 100px;
    left: 30%;
}
.compDescription{
    display: block;
    position: relative;
    width: 80%;
    margin: 0 auto;
    z-index: -500;
}
.compDescription ul{
    display: block;
    position: relative;
    width: 100%;
    margin: 0 auto;
}

.compDescription ul li{
    display: inline-block;
    position: relative;
    width: 30%;
    margin: 10px auto;
}
.compActivity li, .compDescription li{
    list-style: none;
}
.compActivity{
    display: block;
    margin: 10px auto;
    z-index: -500;
}
.compActivity .label{
    color: rgb(62, 208, 42);
    font-weight: bold;
}
.compActivity .area{
    display: block;
    width: 98%;
    border-top: 1px solid #ccc;
}
.compActivity li{
    display: inline-block;
    position: relative;
    margin: 20px 40px;
    z-index: -500;
}
.compActivity li .button{
    display: inline-block;
    position: relative;
    margin: 10px auto;
    bottom: 60px;
    width: auto;
    height: auto;
    color: aquamarine;
}
