.invparams-area .invparams-item{
    display: block;
    width: 90%;
    position: relative;
    margin: 10px auto;
    border-bottom: 3px solid rgba(131, 207, 69, 0.395);
}
.invparams-area .invparams-item span{
    display: inline-block;
    position: relative;
    margin: 0 5px;
    padding: 5px;
}

.invparams-area .invparams-item .label{
    font-size: large;
    font-weight: 600;
    height: 30px;
    background-color: rgba(131, 207, 69, 0.395);
}
.invparams-area .invparams-item .IconUpdateBtn{
    width: 30px;
    height: 30px;
    fill: rgba(131, 207, 69);
}
.invparams-area .invparams-item .IconUpdateBtn .svg{
    width: 100%;
    height: 100%;
}