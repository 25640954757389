.main-body-features td {
    padding-top: 15px;
    padding-bottom: 15px;
}

input[type=checkbox] {
    transition: var(--transition);
    accent-color: var(--primaryColor);
    cursor: pointer;
}

.main-head-features .input {
    margin-left: 20px;
}