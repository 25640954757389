@font-face {
    font-family: "Roboto Thin";
    src: url("../assets/fonts/Roboto/Roboto-Thin.ttf");
}

@font-face {
    font-family: "Roboto Light";
    src: url("../assets/fonts/Roboto/Roboto-Light.ttf");
}

@font-face {
    font-family: "Roboto Regular";
    src: url("../assets/fonts/Roboto/Roboto-Regular.ttf");
}

@font-face {
    font-family: "Roboto Medium";
    src: url("../assets/fonts/Roboto/Roboto-Medium.ttf");
}

@font-face {
    font-family: "Roboto Bold";
    src: url("../assets/fonts/Roboto/Roboto-Bold.ttf");
}

:root {
    /* COLORS */

    --gray: #EEEEEE;
    --lightGray: #F5F5F5;
    --primaryColorGreen: #07a807;
    --primaryColor: #16537e;
    --primaryColorLight: rgba(22,83,126, 0.2);
    --secondaryColor: #C5C5C5;
    --onPrimary: #FFFFFF;
    --errorColor: #B00020;
    --errorColorLight: rgba(176, 0, 32, 0.2);
    --onError: #FFFFFF;
    --surfaceColor: #FFFFFF;
    --secondaryColorSurface: #1A2643;
    --onSurface: rgba(0,0,0,0.8);
    --onSecondarySurface: rgba(197, 197, 197,0.8);
    --disable: rgba(0,0,0,0.4);
    --backgroundColor: #FFFFFF;
    --onBackground: rgba(0,0,0,0.8);
    --borderInputColor: rgba(0,0,0, 0.2);

    /* SIZES */
    
    --nav-width: 300px;
    --radius: 3px;
    --padding-input: 8px 5px;
    --margin-input: 15px;

    /* FONTS */
    
    --font-text-thin: "Roboto Thin";
    --font-text-light: "Roboto Light";
    --font-text: "Roboto Regular";
    --font-text-medium: "Roboto Medium";
    --font-text-bold: "Roboto Bold";

    /* TRANSITIONS */

    --transition: all 0.3s cubic-bezier(.25,.8,.25,1);

    /* SHADOWS */

    --shadow1: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    --shadow2: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    --shadow3: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    --shadow4: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    --shadow5: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}

* {
    box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, p {
    margin: 0;
    padding: 0;
}

body{
    background-color: var(--backgroundColor);
    font-family: var(--font-text);
    color: var(--onBackground);
}

.hide {
    display: none !important;
}

.center {
    text-align: center;
}