.message {
    border-radius: var(--radius);
    margin-top: 20px;
    padding: 10px 5px;
}

.message.error {
    border: 1px solid var(--errorColor);
    background-color: var(--errorColorLight);
}

.message p {
    text-align: center;
    font-family: var(--font-text-medium);
}