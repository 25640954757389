.loader {
  border: 5px solid var(--lightGray);
  border-top: 5px solid var(--primaryColor);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

  
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}