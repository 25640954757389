button {
    background-color: var(--surface);
    border-radius: var(--radius);
    padding: 7px 10px;
    cursor: pointer;
    border: 1px solid var(--surface);
    box-shadow: var(--shadow1);
    transition: var(--transition);
}

button:hover {
    box-shadow: var(--shadow2);
}

button.primary {
    background-color: var(--primaryColorGreen);
    border: 1px solid var(--primaryColorGreen);
    color: var(--onPrimary);
}