.navlink {
    display: flex;
    align-items: center;
    padding-left: 7px;
    font-size: 1em;
    cursor: pointer;
    border-radius: 0 40px 40px 0;
}

.navlink svg{
    display: inline-block;
    position: relative;
    width: 50px;
    height: 50px;
}
.navlink, .navlink svg, .navlink label{
    transition: var(--transition);
}

.navlink:hover {
    background-color: var(--lightGray);
}

.navlink:hover label {
    color: var(--onSurface);
}

.navlink:hover svg {
    fill: var(--onSurface);
}

.navlink svg {
    fill: var(--secondaryColorSurface);
    transform: scale(0.5);
}

.navlink label {
    cursor: pointer;
    color: var(--secondaryColorSurface);
}

.navlink.active {
    background-color: var(--primaryColorLight);
}

.navlink.active label {
    color: var(--primaryColorGreen);
    font-family: var(--font-text-bold);
}

.navlink.active svg {
    fill: var(--primaryColor);
}

.submenu li {
    padding-left: 20px;
}

.arrow-drop-up {
    transform: scale(0.5) rotate(180deg) !important;
}
/**/
.page-navlink .navlink{
    width: 200px;
}