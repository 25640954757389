.buttton-circle {
    background-color: var(--surface);
    border-radius: 25px;
    padding: 7px 10px;
    cursor: pointer;
    border: 1px solid var(--surface);
    box-shadow: var(--shadow1);
    transition: var(--transition);
}

.buttton-circle:hover {
    box-shadow: var(--shadow2);
}

.buttton-circle.primary {
    background-color: var(--primaryColor);
    border: 1px solid var(--primaryColor);
    color: var(--onPrimary);
}
.buttton-circle .info {
    background-color: rgb(13, 86, 182);
    border: 1px solid var(--primaryColor);
    color: var(--onPrimary);
}
.buttton-circle-info {
    background-color: rgba(13, 86, 182, 0);
    border: 1px solid rgb(13, 86, 182);
    color: rgb(13, 86, 182);
}