.dsh-data-stats{
    display: block;
    width: 99%;
    position: relative;
    bottom: 60px;
    border-radius: 25px;
    border: 2px solid #28D146;
}
.dsh-data-stats ul{
    display: block;
    width: 100%;
    position: relative;
}
.dsh-data-stats .row{
    position: relative;;
    left: 2%;
    top: 20px;

}
.dsh-data-stats .chart-view {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
}
.dsh-data-stats .chart-view canvas{
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 auto;
}
.dsh-data-stats .data-item-g{
    display: inline-block;
    width: 100%;
    height: 300px;
    position: relative;
    margin: 0 auto;
}
.dsh-data-stats .data-row{
    display: inline-block;
    width: 17%;
    height: 380px;
    position: flex;
    margin: 10px 0;
    list-style: none;
}
.dsh-data-stats #data-row-center{
    width: 58%;
    margin-left: 2%;
}
.dsh-data-stats #data-row-center .data-item{
    display: inline-block;
    position: relative;
}
.dsh-data-stats #data-row-center .data-item:first-child{
    margin-left: -1%;
    
}
.dsh-data-stats #data-row-center .data-item:nth-child(2){
    margin-left: 10%;
    margin-right: 6%;
}
.dsh-data-stats #data-row-center .data-item:nth-child(3){
    left: 6%;
}
.dsh-data-stats #data-row-right{
    margin-left: 3%;
}
.dsh-data-stats #data-item-cmd{
    height: 120px;
}
.dsh-data-stats .data-item{
    display: block;
    width: 200px;
    height: 70px;
    position: relative;
    margin: 10px 4%;
    left: 1%;
    list-style: none;
    border-radius: 25px;
}
.dsh-data-stats .data-item-buttons{
    display: inline-block;
    width: 18%;
    height: 80px;
    position: flex;
    margin: 10px 1%;
    list-style: none;
    border-radius: 25px;
    
}
.dsh-data-stats .data-item-buttons .item-btn{
    display: inline-block;
    width: 45%;
    height: 50px;
    text-align: center;
    position: relative;
    margin: 0 1%;
    padding: 12px 0;
    list-style: none;
    border-radius: 16px;
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    background-color: #28D146;
}
.dsh-data-stats .data-item span{
    display: block;
    width: 80%;
    float: left;
    text-align: center;
    margin-left: 10%;
    margin-top: 8px;
    color: #000;
}
.dsh-data-stats .data-item .item-value{
    font-size: 18px;
    font-weight: 600;
}
.dsh-data-stats .data-item .item-label{
    font-size: 16px;
    font-weight: 400;
}
.horizontal-navigation{
    position: relative;
    display: block;
    margin: 0 auto;
    width: 98.9%;
    height: 350px;
    bottom: 40px;
    border: 2px solid #28D146;
    border-radius: 25px;
}
.dsh-data-stats .bg-grey{
    background-color: rgb(243, 245, 241);
}
.dsh-data-stats .bg-chartreuse{
    background-color: chartreuse;
}
.dsh-data-stats .bg-darkgreen{
    background-color: #28D146;
}
.dsh-data-stats .bg-lightgreen{
    background-color: #5FFC7B;
}
.dsh-data-stats .bg-dodgerblue{
    background-color: var(--primaryColorGreen)
}
