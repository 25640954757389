.select select {
    background-color: var(--surfaceColor);
    outline: none;
    border: 1px solid var(--borderInputColor);
    border-radius: var(--radius);
    padding: var(--padding-input);
    width: 100%;
}

.select label {
    user-select: none;
    display: inline-block;
    margin-bottom: 8px;
    font-family: var(--font-text-medium);
}

.input-line .select{
    width: 320px;
}