.card-flat-sqare-rouded {
    display: inline-block;
    position: relative;
    width: 67%;
    height: 250px;
    margin: 0 auto;
    cursor: default;
    align-items: center;
    font-size: 1em;
    border-radius: 25px;
    background-color: rgb(194, 235, 171);
    box-shadow: 0 0 0 13px rgb(190, 226, 168);
}
.card-flat-sqare-rouded .card-header{
    display: block;
    position: relative;
    margin: 10px 0;
    width: 100%;
    height: 30px;
    top: 30px;
    font-weight: bold;
    color: #165709;
}
.card-flat-sqare-rouded .card-content{
    display: block;
    position: relative;
    height: 250px;
    top: 30px;
}


.card-flat-sqare-rouded label {
    color: #165709;
}

/**/