.cash-reg-console{
    display: block;
    position: absolute;
    width: 95%;
    height: 720px;
    z-index: -10;
}
.cash-reg-console .console-left, .cash-reg-console .console-right{
    display: inline-block;
    position: relative;
    margin: 0 auto;
    width: 30%;
    height: 100%;
    
}
.cash-reg-console .console-right{
    bottom: 0;
    float: right;
    width: 69%;
}
.cash-reg-console .interface{
    display: inline-block;
    position: relative;
    border-radius: 12px;
    margin: 5px auto;
    background-color: rgb(194, 235, 171);/*#28D146*/
    color: #ffffff;
}
.cash-reg-console .screen{
    width: 100%;
    height: 100px;
}
.cash-reg-console .screen h2{
    position: relative;
    margin: 0 10px;
    top: 10px;
}
.cash-reg-console .list-category{
    width: 100%;
    height: 230px;
}
.cash-reg-console .list-product{
    width: 100%;
    height: 450px;
}
.cash-reg-console .options{
    width: 100%;
    height: 120px;
}
.cash-reg-console .options .disabled{
    display: none;
}
.cash-reg-console .table{
    width: 100%;
    height: 290px;
}
.cash-reg-console .table table{
    display: block;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}
.cash-reg-console .table table thead{
    width: 100%;
    color: var(--secondaryColor);
}

.cash-reg-console .table table th:first-child{
    width: 5%;
}
.cash-reg-console .table table td{
    font-size: 11px;
}
.cash-reg-console .calculator{
    display: none;
    width: 49%;
    height: 150px;
}
.cash-reg-console .resume{
    width: 49%;
    height: 150px;
    margin: 0 10px 0 0;
}
.cash-reg-console .resume .prices{
    display: block;
    position: relative;
    font-size: 14px;
}
.cash-reg-console .resume .prices span{
    display: inline-block;
    position: relative;
    margin: 5px;
}
/*.console-right .list-product area ul, .console-right .list-category area ul{
    overflow-y: scroll;
    height: 90%;
}*/
.cash-reg-console .list-product .hidden{
    display: none;
}
.cash-reg-console .list-product .search-bar, .cash-reg-console .list-product .title{
    display: inline-block;
    width: 49%;
    position: relative;
    margin: 0 auto;
}
.cash-reg-console .list-product .search-bar input{
    position: relative;
    width: 50%;
    left: 40%;
    margin: 10px;
}

.cash-reg-console .area{
    display: block;
    width: 100%;
    height: 100%;
}
.cash-reg-console .list-category .area ul, .cash-reg-console .list-product .area ul{
    overflow-y: scroll;
    height: 90%;
}
.cash-reg-console .area li{
    display: inline-block;
    position: relative;
    width: 31%;
    height: 50px;
    margin: 10px 1%;
    list-style: none;
}
.cash-reg-console .area li:hover .area-item span{
    background-color: rgba(92, 171, 224, 0.541);
}
.cash-reg-console .area li:active  .area-item span{
    background-color: rgb(197, 197, 197);
}
.cash-reg-console .area .area-item{
    width: 100%;
    height: 100%;
    text-align: center;
    border-radius: 5px;
    padding: 3px;
    background-color: rgb(64, 224, 126);
}
.cash-reg-console .area .area-item span{
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 10px;
    top: 5px;
    text-align: center;
    border-radius: 5px;
    background-color: #ffffff;
    color: var(--onSurface);
    font-size: 16px;
    font-weight: 600;
}
.cash-reg-console .options .option-item{
    display: inline-block;
    position: relative;
    list-style: none;
    margin: 5px;
}
.btn-delete .button{
    display: inline-block;
    position: relative;
    margin: 10px auto;  
    width: 40px;
    height: auto;
    fill: red;
}
.input-focus input:focus{
    outline: auto; 
    background-color: aqua;
    color: burlywood;
}