.modal-content {
    background-color: var(--surfaceColor);
    box-shadow: var(--shadow5);
    border-radius: 7px;
    overflow: hidden;
}

.notify {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 100px;
}

.notify .modal-confirm {
    width: 500px;
}

.notify.transparent {
    background-color: rgba(0, 0, 0, 0);
}

.notify.small-modal .modal-content {
    width: 400px;
}

.notify.medium-modal .modal-content {
    width: 700px;
}

.modal-content svg {
    width: 24px;
    height: 24px;
    cursor: pointer;
    fill: var(--onPrimary);
}

.modal-content-foot, .modal-content-head, .modal-content-body {
    padding: 10px 20px;
}

.modal-content-body {
    padding-top: 25px;
}

.modal-content-head {
    background-color: var(--primaryColor);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modal-content-head label {
    font-size: 1.2em;
    font-family: var(--font-text-medium);
    user-select: none;
    color: var(--onPrimary);
}

.modal-content-foot {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid var(--lightGray);
}

.modal-content-foot button {
    margin-left: 10px;
}
/**/
.notify .modal-success .modal-content-head{
    background-color: rgb(79, 224, 125);
}
.notify .modal-info .modal-content-head{
    background-color: cornflowerblue;
}
.notify .modal-warning .modal-content-head{
    background-color: rgb(237, 157, 100);
}
.notify .modal-danger .modal-content-head{
    background-color: rgb(243, 63, 63);
}