.text-left{
    text-align: left;
}
td svg{
    width: 45px;
    height: 45px;
}
td .icon-success{
    fill: rgb(92, 211, 148);
    color: rgb(92, 211, 148);
}
td .icon-danger{
    stroke: rgb(240, 125, 75);
    fill: rgb(240, 125, 75);
    color: rgb(240, 125, 75);
}
.invoice-details{
    display: block;
    width: 100%;
    height: 500px;
    overflow-y: scroll;
}
.view-ticket {
    width: 50%;
    margin: 0 auto;
}
.view-ticket .invoice-header{
    font-size: 8px;
}
.view-ticket .invoice-body #invoice-table table thead th, .view-ticket .invoice-body #invoice-table table tbody tr td{
    font-size: 8px;
}
.invoice-details .invoice-header .company-infos p{
    font-size: 9px;
}
.invoice-details .invoice-header .client-infos{
    display: inline-block;
    position: relative;
    width: 45%;
    left: 55%;
    border: 2px solid #000;
    border-radius: 6px;
}
.invoice-details .invoice-header .client-infos p{
    position: relative;
    height: 20px;
    margin: 5px 10px;
    top: 10px;
    font-size: 14px;
}
.invoice-details .invoice-header .ref-infos{
    display: block;
    position: relative;
    width: 45%;
}
.invoice-details .invoice-header .ref-infos thead{
    background-color: transparent;
}
.invoice-details .invoice-header .ref-infos thead th{
    background-color: transparent;
    font-style: normal;
    font-weight: 100;
}
.invoice-details .invoice-header .ref-infos table{
    border: 1px solid #000;
}
.invoice-details .invoice-header .ref-infos td{
    border: 1px solid #000;
}
.invoice-details .invoice-body{
    display: block;
    position: relative;
    margin: 10px 0;
    width: 100%;

}
.invoice-details .invoice-body #invoice-table{
    display: block;
   width: 100%;
}
.invoice-details .invoice-body #invoice-table tbody{
    width: 100%;
    /*overflow-y: scroll;
    max-height: 200px;*/
}
.invoice-details .invoice-body #invoice-table th{
    font-size: 12px;
    font-weight: 200;
}
.invoice-details .invoice-body #invoice-table td{
    font-size: 11px;
    font-weight: 200;
}
.invoice-details .invoice-body #invoice-table th:first-child{
    width: 2%;
}
.invoice-details .invoice-body #invoice-table .th-number{
    width: 2%;
}
.invoice-details .invoice-body #invoice-table tfoot {
    display: block;
    position: relative;
    width: 100%;
}
.invoice-details .invoice-body #invoice-table tfoot table {
    position: relative;
    width: 50%;
    left: 50%;
}
.invoice-details .invoice-body #invoice-table tfoot table thead{
   background-color: transparent;
}
.invoice-details .invoice-body #invoice-table tfoot table thead th{
   font-weight: 100;
   font-size: 13px;
}
