.label-infos{
    display: block;
    position: relative;
    width: 100%;
}
.label-infos .icon{
    display: inline-block;
    position: relative;
    width: 25px;
    height: 25px;
}
.label-infos svg{
    fill: rgb(62, 208, 42);
}
.label-infos label{
    display: inline-block;
    position: relative;
    width: 80%;
    color: rgb(62, 208, 42);
    font-weight: bold;
    margin: 0 10px;
}
.label-infos p{
    font-weight: lighter;
    margin: 10px 0;
}