#login-wrap{
    background-color: var(--primaryColorGreen);
    padding-top: 100px;
    height: 100vh;
}

#login {
    background-color: var(--surfaceColor);
    border-radius: 8px;
    width: 500px;
    margin: auto;
    box-shadow: var(--shadow4);
    padding: 50px 15px;
}

#login h2 {
    font-family: var(--font-text-bold);
    margin-bottom: 30px;
    font-size: 1.5em;
}

#login .input {
    margin-bottom: 20px;
}
#login .logo {
    display: block;
    position: relative;
    width: 80px;
    height: 80px;
    margin: 0 auto;
    bottom: 30px;
}
#login .logo img{
    width: 100%;
    height: 100%;
}
