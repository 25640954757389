.card-activity{
    display: block;
    position: relative;
    width: 200px;
    height: 120px;
    border-radius: 5px;
    border-color: #555;
    box-shadow: 1px 2px 4px 1px #555;
}
.card-activity label{
    display: block;
    position: relative;
    width: 90%;
    height: auto;
    top: 20px;
    text-align: center;
}
.card-activity .icon{
    display: block;
    position: relative;
    width: 25px;
    height: auto;
    margin: 0 auto;
    top: 20px;
}