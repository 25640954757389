.main-head-users {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.main-head-users .table-infos {
    display: flex;
    align-items: center;
}

.table-infos label {
    font-size: 0.8em;
    user-select: none;
}

.table-infos svg {
    transform: scale(0.4);
    cursor: pointer;
}

svg.disable {
    fill: var(--disable);
    cursor: not-allowed;
}

.main-head-users .input {
    flex: 1;
    margin-right: 20px;
}