.data-stats{
    display: block;
    position: relative;
    width: 90%;
    margin: 10px auto;
}
.data-stats li{
    display: inline-block;
    position: relative;
    list-style: none;
    margin: 0 5px;
}
.data-stats .data-item{
   font-size: 15px;
   border-left: 3px solid rgb(19, 176, 124);
}
.data-stats .data-item .item-value{
    font-weight: bold;
   color: rgb(19, 176, 124);
}
.page-navlink {
    display: block;
    width: 95%;
}
.page-navlink ul{
    display: block;
    width: 100%;
}
.page-navlink ul li{
    display: inline-block;
    position: relative;
    height: 50px;
    list-style: none;
    margin: 0 5px;
    background-color: rgb(69, 198, 55);
    cursor: pointer;
}
.page-navlink ul .item{
    display: block;
    position: relative;
    padding: 0 5px; 
    color: #fff;
    
}
.page-navlink ul .item .profile{
    display: inline-block;
    position: relative;  
    width: 40px;
    height: 40px;
    outline: 1px solid rgb(187, 255, 0);
}
.page-navlink ul .item svg{
    fill: #fff;
}
.page-navlink ul .item .label{
    display: inline-block;
    position: relative; 
    bottom: 20px;
}