header {
    border-bottom: 1px solid var(--gray);
    display: flex;
    justify-content: space-between;
    background-color: var(--surfaceColor);
    color: var(--onSurface);
    padding: 5px 20px 5px 0;
}

header div {
    display: flex;
    align-items: center;
}

#header-infos {
    flex: 1;
    justify-content: space-between;
}

#logo {
    width: var(--nav-width);
    padding-left: 20px;
}

#logo img {
    width: 28px;
}

#logo h2 {
    font-family: var(--font-text-bold);
    margin-left: 7px;
    font-size: 1.5em;
}

#breadcrumb svg {
    fill: black;
    transform: scale(0.4);
}

#breadcrumb a {
    user-select: none;
    text-decoration: none;
    font-family: var(--font-text-medium);
    color: var(--primaryColor);
}

#breadcrumb a:hover {
    color: var(--primaryColor);
    transition: var(--transition);
}

#breadcrumb #active-page {
    color: var(--onSurface);
}

#actions svg {
    transform: scale(0.7);
}

#profile-picture {
    background-color: var(--gray);
    border-radius: 50%;
    overflow: hidden;
    padding: 2px;
    margin: 0 5px;
}

.icon-button {
    cursor: pointer;
    border-radius: 50%;
    transition: var(--transition);
}

.icon-button:hover {
    background-color: var(--gray);
}
/**/

header .navigation .icon-button{
    width: 40px;
    height: 40px;
    fill: #4ef13f;
}

.Modal-nav{
    display: block;
    position: absolute;
    top: 200px;
}
.Modal-nav .notify{
    width: 100%;
    top: 200px;
}
.Modal-nav .notify .modal-content{
    width: 90%;
}