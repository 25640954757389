nav.v-nav, ul{
    padding: 0;
    margin: 0;
    margin-bottom: 30px;
}
nav.v-nav ul li label, nav.v-nav ul .title{
    visibility: hidden;
}
nav.v-nav:hover ul li label, nav.v-nav:hover ul .title{
    visibility: visible;
    
}

.title {
    list-style: none;
    font-family: var(--font-text-bold);
    font-size: 1.3em;
    padding-left: 20px;
    margin-bottom: 5px;
}

li {
    user-select: none;
}

nav.v-nav {
    background-color: var(--onPrimary);
    color: var(--primaryColorGreen);
    overflow-y: none;
    scroll-behavior: smooth;
    scrollbar-width: 5px;
    max-height: 780px;
}
nav.v-nav:hover {
    overflow-y: scroll;
}
