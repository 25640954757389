.input input {
    width: 100%;
    border: 1px solid var(--borderInputColor);
    border-radius: var(--radius);
    padding: var(--padding-input);
}

.input label {
    user-select: none;
    display: inline-block;
    margin-bottom: 8px;
    font-family: var(--font-text-medium);
}

input:focus {
    border: 1px solid var(--primaryColor);
    box-shadow: none;
    outline: none;
}

.input-line {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--margin-input);
}

.input-line .input {
    width: 320px;
}

.error-input {
    color: var(--errorColor);
    font-family: var(--font-text-light) !important;
    font-size: 0.9em;
    margin-top: 5px;
}